import React from "react"
import moment from "moment"
import Cache from "./Cache"

class UtilService {

	static getDateTime(dateTimeStr) {
		if (dateTimeStr && dateTimeStr != "")
			return moment.unix(new Date(dateTimeStr).getTime() / 1000).format("YYYY-MM-DD HH:mm:ss")

		return "-"
	}

	static getDateTime3(dateTimeStr) {
		if (dateTimeStr && dateTimeStr != "")
			return moment.unix(new Date(dateTimeStr).getTime() / 1000).format("YYYY-MM-DD HH:mm")
		return "-"
	}

	static getDateTime2ByFormat(dateTimeStr, format) {
		if (dateTimeStr) {
			if (typeof dateTimeStr == "string") {
				if (dateTimeStr.substring(0, 10) == "0001-01-01")
					return ""
			}
		}
		if (dateTimeStr && dateTimeStr != "")
			return moment.unix(new Date(dateTimeStr).getTime() / 1000).format(format)
		return "-"
	}
	static getDate(dateTimeStr) {
		if (!dateTimeStr || dateTimeStr == "")
			return ""

		return moment.unix(new Date(dateTimeStr).getTime() / 1000).format("YYYY-MM-DD")
	}
	static getTime(dateTimeStr) {
		return moment.unix(new Date(dateTimeStr).getTime() / 1000).format("HH:mm:ss")
	}
	static getTimeExceptSecond(dateTimeStr) {
		return moment.unix(new Date(dateTimeStr).getTime() / 1000).format("HH:mm")
	}
	static getDateTimeByFormat(ts, format) {
		return moment.unix(ts / 1000).format(format)
	}
	static getDateTime2(ts) {
		return moment.unix(ts / 1000).format("HH:mm:ss")
	}

	static getDateTimeFromSeconds(secs, format) {
		return moment.unix(secs).format(format)
	}
	static getRemainedTime(dateTimeStr) {
		var delta = UtilService.getRemainedSeconds(dateTimeStr)
		return UtilService.getTimeFromSeconds(delta)
	}
	static getRemainedTime2(dateTimeStr, current) {
		var delta = UtilService.getRemainedSeconds(dateTimeStr, current)
		return UtilService.getTimeFromSeconds(delta)
	}
	static getRemainedSeconds(dateTimeStr, current) {
		var curr = current ? current : new Date()
		var delta = Math.floor(new Date(dateTimeStr).getTime() / 1000 - curr.getTime() / 1000)
		return delta
	}

	static getRemainedSeconds2(dateTimeStr, current) {
		var curr = current ? current : new Date()
		var delta = Math.floor(moment(dateTimeStr).toDate().getTime() / 1000 - curr.getTime() / 1000)
		return delta
	}

	static getPassDate(dateTimeStr) {
		var days = Math.floor((Date.now() - new Date(dateTimeStr).getTime()) / 1000 / 3600 / 24)
		return days
	}

	static getFaqType(typeCode) {
		switch (typeCode) {
		case "AA":
			return UtilService.getLangByCode("label.loginaccount", "로그인/계정")
		case "BB":
			return UtilService.getLangByCode("label.token", "코인")
		case "CC":
			return UtilService.getLangByCode("label.membership", "멤버십")
		case "DD":
			return UtilService.getLangByCode("label.wallet", "전자지갑")
		case "EE":
			return UtilService.getLangByCode("label.other", "기타")
		}

		return "Unknown"
	}

	static getLangByCode(code, defaultString) {
		return Cache.getLang(localStorage.lang || "ko", String(code)) || defaultString || String(code)
	}

	static getCurrentLang() {
		return localStorage.lang || "ko"
	}

	static validateEmail(email) {
		// var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
		return re.test(String(email).toLowerCase())
	}

	// 공지사항 점검페이지 show 시간 (실제 공지 점검페이지가 나오는 시간 입력) 
	static isMaintenance() {
		var diff1 = UtilService.getRemainedSeconds2("2024-10-01 00:00+0900")
		var diff2 = UtilService.getRemainedSeconds2("2034-10-01 00:00+0900") 
		if (diff1 < 0 && diff2 > 0) {
			return true
		}
		return false
	}

	//공지사항 사전 공지팝업 show 시간 (공지 점검페이지 나오기 전까지의 시간을 입력)
	static isMaintenancePopup() {
		var diff1 = UtilService.getRemainedSeconds2("2024-08-08 00:00+0900")
		var diff2 = UtilService.getRemainedSeconds2("2024-08-11 02:00+0900")
		if (diff1 < 0 && diff2 > 0) {
			return true
		}
		return false
	}

	static getUserName(username) {
		if (username.length == 2) {
			return username[0] + "*"
		} else if (username.length > 2) {
			return username[0] + "*" + username[username.length - 1]
		}
		return username
	}

	static sprintf() {
		var args = arguments,
			string = args[0],
			i = 1
		return string.replace(/%((%)|s|d)/g, function (m) {
			// m is the matched format, e.g. %s, %d
			var val = null
			if (m[2]) {
				val = m[2]
			} else {
				val = args[i]
				// A switch statement so that the formatter can be extended. Default is %s
				switch (m) {
				case "%d":
					val = parseFloat(val)
					if (isNaN(val)) {
						val = 0
					}
					break
				}
				i++
			}
			return val
		})
	}
}

export default UtilService
