import React from 'react';
import PropTypes from 'prop-types';

class Maintenance extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }

    UNSAFE_componentWillMount() {
        var hd = document.getElementById("yesConciseGnbWrap");
        if (hd)
            hd.parentNode.removeChild(hd);
    };

    render() {
        // return (
        //     <div>
        //     <div id="yesWrap">
        //         {/* <!-- #################### 간략 HEADER 영역 시작 #################### --> */}
        //         <div id="ySHeader">
        //             <div className="ySHeaderAera">
        //                 <h1>
        //                     <a className="lnk_logo" href="http://www.yes24.com/main/default.aspx"><img src="https://secimage.yes24.com/sysimage/renew/sLayout/logo.png" border="0" alt="YES24" /></a>
        //                 </h1>
        //             </div>
        //         </div>
        //         {/* <!-- #################### 간략 HEADER 영역 끝 #################### --> */}
        //         {/* <!-- #################### 간략 Content 영역 시작 #################### --> */}
        //         <div id="ySContent">
        //             <div className="ySContRow">
        //                 <div className="error_tit">
        //                     서비스 점검 안내 
        //                 </div>
        //                 <div className="error_des mgt40">
        //                     <p>
        //                     안녕하세요. sey코인몰 관리자입니다.<br/><br/><br/>
                            
        //                     보다 안정적인 서비스 제공을 위해 정기점검중 입니다.<br/>

        //                     점검 중에는 서비스가 일시적으로 중단되오니,<br/>

        //                     아래 일정 참고하시어 이용에 차질 없으시기 바랍니다.<br/>

        //                     감사합니다.
        //                     </p>
        //                     <p>

        //                     점검 일정 : 2024년 8월 11일(일) 오전 02:00 ~ 06:00<br/>

        //                     * 진행 상황에 따라 일정이 변경될 수 있습니다.
                                
        //                     </p>
        //                 </div>
        //                 {/* <div className="error_btn">
        //                     <a href="http://www.yes24.com" className="btnC w_220 xb_size btn_blue"><span className="bWrap" style={{height: '60px'}}><em className="txt">YES24메인</em></span></a>
        //                     <a href="http://www.yes24.com/Templates/FTMy1vs1AskEtc.aspx" className="btnC w_220 xb_size"><span className="bWrap" style={{height: '60px'}}><em className="txt">오류 신고</em></span></a>
        //                 </div> */}
        //                 {/* <div className="error_des mgt40">
        //                     고객센터 : 1544-3800
        //                 </div> */}
        //             </div>
        //         </div>
        //         {/* <!-- #################### 간략 Content 영역 끝 #################### --> */}
        //         {/* <!-- #################### 간략 FOOTER 영역 시작 #################### --> */}
        //         <div id="ySFooter">
        //             <div className="ySFooterAera">
        //                 <address>	
        //                     Copyright &#0169; <strong>YES24 Corp.</strong> All Rights Reserved.				
        //                 </address>
        //             </div>
        //         </div>
        //         {/* <!-- #################### 간략 FOOTER 영역 시작 #################### --> */}
        //     </div>
        // </div>
        // );
        return (
            <div>
                <div id="yesWrap">
                    <link rel="stylesheet" type="text/css" href="https://image.yes24.com/images/00_Event/2024/0902popup/0902popup.css?ver=240903bbb"/> 
                    <div class="evePopArea">
                        <div class="evePop pop02">
                            <div class="evePopTit">
                                <img src="https://image.yes24.com/images/00_Event/2024/0902popup/img_sey01.png" height="77" border="0" alt="sey코인" />
                            </div>
                            <div class="evePopCon">
                                <p class="tit_txt">안내</p>
                                <p class="tit_des">안녕하세요. 예스24입니다.</p>
                                <p class="tit_des">sey코인 서비스는 <strong>2024년 9월 30일부로 종료</strong>되었습니다.</p>
                                <p class="tit_des">sey코인몰, sey.io, sey월렛 앱은 <span class="ws_n">더 이상 사용이 불가능합니다.</span></p>
                                <p class="tit_des">그동안 sey코인 서비스를 이용해 주셔서 감사합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Maintenance.contextTypes = {
    router: PropTypes.object.isRequired
}

export default Maintenance;
